body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.root {
  min-height: calc(100vh - 120px) !important;
}

.spacer {
  height: 100px;
  width: 100%;
}

.spacer-half {
  height: 50px;
  width: 100%;
}

.card {
  box-shadow: 0 2px 15px -3px rgb(0 0 0 / 7%), 0 10px 20px -2px rgb(0 0 0 / 4%);
}


/* Navbar */

.navbar-brand {
  margin-left: 10px;
}

.offcanvas-header {
  margin-bottom: 70px;
}

#navContainer {
  width: 100%;
  height: 90px;
  color: white;
  box-shadow: 3px 3px 9px 6px rgba(0, 0, 0, .2);
  z-index: 2;
}

.navbar-toggler {
  margin-right: 10px;
  border: none !important;
}


/* Hero */

.hero-image {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("images/heroImage.jpg");
  /*height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc((var(--vh, 1vh) * 100) - 90px);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

#hero {
  text-align: center;
  color: white;
}

.hero-social {
  bottom: 5%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  color: white;
}

#socialLink {
  color: rgba(255, 255, 255, 0.6);
}

#socialLink:hover {
  color: rgba(255, 255, 255, 1);
}

#heroPhone {
  font-size: 40px;
  margin: 10px;
}

#heroSMS {
  font-size: 40px;
  margin: 10px;
}

#heroFB {
  font-size: 40px;
  margin: 10px;
}

#heroInsta {
  font-size: 40px;
  margin: 10px;
}

#heroLogo {
  margin: 0 auto;
  max-width: 600px;
  width: 80%;
  height: auto;
}

/* Gallery */

.img-grid {
  margin: 20px auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
}

.img-wrap {
  overflow: hidden;
  height: 0;
  padding: 50% 0;
  position: relative;
  opacity: 0.8;
}

.img-wrap img {
  min-width: 100%;
  min-height: 100%;
  max-width: 150%;
  position: absolute;
  top: 0;
  left: 0;
}

.backdrop {
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.5);
}

.backdrop img {
  display: block;
  max-width: 80%;
  max-height: calc(90% - 90px);
  margin: 150px auto 0 auto;
  box-shadow: 3px 5px 7px rgba(0,0,0,0.5);
  border: 3px solid white;
}

.linkColor {
  color: #0094de !important;
}

.modalTextContainer {
  background-color: white;
  width: 80%;
  max-width: 806px;
  box-shadow: 3px 5px 7px rgba(0,0,0,0.5);
  border: 3px solid white;
  margin: 0 auto;
  padding-top: 30px;
  padding-bottom: 30px;
}

.modalText {
  color: black;
  width: 80%;
  margin: 0 auto;
}

/* About */

.jumbotron {
  padding: 2rem 1rem;
  margin-top: 30px;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: .3rem;
}

.vertical-center {
  display: flex;
  align-items: center;
}

#about1 {
  width: 100%;
  height: auto;
}
#about2 {
  width: 100%;
  height: auto;
}

.disclaimer {
  font-size: 0.7rem;
}

.shirtImg {
  max-width: 500px;
  width: 100%;
  height: auto;
}

.preferred {
  max-width: 400px;
  width: 100%;
  height: auto;
}

.service {
  min-width: 250px;
}

.service:hover {
  cursor: pointer;
}

/* Partners */

.display-4 {
  display: inline-block;
}

.form-text {
  display: inline-block;
}

/* Calculation */

.calc-btn {
  width: 100% !important;
}

.spacer-calc {
  height: calc(3.5rem + 2px);
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
  margin-bottom: 1rem;
}

#calcImage {
  max-width: 900px;
  width: 100%;
  height: auto;
}

/* Contact */

.form-label {
  padding-left: calc(var(--bs-gutter-x) * 1) !important;
}

.btn {
  width: 500px;
  height: calc(3.5rem + 2px);
}

.btn-primary {
  background-color: #0094de !important;
  border-color: #0094de !important;
}

.btn-primary:hover {
  background-color: #40a9dd !important;
  border-color: #40a9dd !important;
}

#contactLink {
  font-size: 26px;
  color: #6c757d;
}

#addressLink {
  font-size: 18px;
  color: #6c757d;
  text-decoration: none;
}

#contactImage {
  width: 100%;
  height: auto;
}

#mapsContainer {
  --bs-gutter-x: 0rem !important;
  height: 700px !important;
}

#mapsContainer > div > div {
  height: 700px !important;
}

/* Testimonials */

.testimonial-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40px auto;
  max-width: 700px;
}
          
.testimonial-container p{
  text-align: center;
  margin-bottom: 20px;
}

.testimonial-nav {
  display: flex;
}
            
.testimonial-nav span{
  height: 20px;
  width: 20px;
  margin: 0 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.testimonial-nav span::before {
  content: "";
  height: 6px;
  width: 6px;
  background-color: #8b99a1;
  border-radius: 50%;
  transition: background-color 0.4 ease;
}

.testimonial-nav span:hover::before {
  background-color: #5d6c7f;
}

.testimonial-nav span[data-quote="${active}"]:before {
  background-color: #5d6c7f;
}

.backdrop img.modalImage {
  margin: 0 auto;
  max-width: 200px;
  max-height: 100%;
}

.ebayIframe {
  width: 100%;
  height: calc(100vh - 90px - 83px - 1.5rem);
}

/* Footer */

.footerLink {
  font-size: 24px;
}

@media only screen and (min-width: 992px) {
  .navbar {
    --bs-navbar-nav-link-padding-x: 0.9rem !important;
  }
}

@media only screen and (max-width: 991px) {
  #overlay-content .nav-link {
    margin: 20px 30px !important;
    font-size: 1.3rem;
    letter-spacing: 2px;
    font-weight: bold;
  }

  .footerCopy {
    font-size: 0.6rem;
  }
}

@media only screen and (max-width: 912px) {
  .form-floating.mb-3 {
    width: 100% !important;
  }

  .col-auto.form-text {
    display: none;
  }

  .submitBtn {
    width: 100% !important;
  }

  .modalText {
    width: 90%;
  }
}

@media only screen and (max-width: 500px) {
  .hero-social {
    width: 100%;
    align-items: center;
    text-align: center;
  }

  .contactInfo {
    padding: 0px !important;
  }

  #addressLink {
    font-size: 16px;
  }

  #addressLink i {
    font-size: 20px;
  }

  .partners {
    width: 150px;
    height: auto;
  }

  .service {
    min-width: 180px;
    min-height: 246px;
  }
}